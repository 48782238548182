.text-effect--disco-party {
  color: hsla(0, 0%, 0%, 0.9);
  letter-spacing: 5px;
  text-transform: uppercase;
  animation: move linear 1000ms infinite;
}

@keyframes move {
  0% {
    text-shadow: 2px -2px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
  25% {
    text-shadow: -2px -2px 0 hsla(180, 100%, 50%, 1),
      -2px -2px 0 hsla(180, 100%, 50%, 1), -2px -2px 0 hsla(180, 100%, 50%, 1),
      -1px -1px 0 hsla(180, 100%, 50%, 1), 2px 2px 0 hsla(0, 100%, 50%, 1),
      2px 2px 0 hsla(0, 100%, 50%, 1), 2px 2px 0 hsla(0, 100%, 50%, 1),
      1px 1px 0 hsla(0, 100%, 50%, 1);
  }
  50% {
    text-shadow: -2px 2px 0 hsla(0, 100%, 50%, 1),
      -2px 2px 0 hsla(0, 100%, 50%, 1), -2px 2px 0 hsla(0, 100%, 50%, 1),
      -1px 1px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(180, 100%, 50%, 1),
      2px -2px 0 hsla(180, 100%, 50%, 1), 2px -2px 0 hsla(180, 100%, 50%, 1),
      1px -1px 0 hsla(180, 100%, 50%, 1);
  }
  75% {
    text-shadow: 2px 2px 0 hsla(180, 100%, 50%, 1),
      2px 2px 0 hsla(180, 100%, 50%, 1), 2px 2px 0 hsla(180, 100%, 50%, 1),
      1px 1px 0 hsla(180, 100%, 50%, 1), -2px -2px 0 hsla(0, 100%, 50%, 1),
      -2px -2px 0 hsla(0, 100%, 50%, 1), -2px -2px 0 hsla(0, 100%, 50%, 1),
      -1px -1px 0 hsla(0, 100%, 50%, 1);
  }
  100% {
    text-shadow: 2px -2px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
}
