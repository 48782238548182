/* .ios ion-toolbar ion-title {
  text-align: start;
  font-size: 14px;
  color: var(--ion-color-step-800);
  padding: 0 0 0 20px;
} */

/* .ios ion-toolbar {
  padding-top: 16px !important;
} */

ion-modal {
  /* --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
  --border-radius: var(--border-radius--large) var(--border-radius--large) 0px 0px;
  --border-style: 0px solid black; */
}

ion-input.input--error {
  border-color: var(--ion-color-danger);
}

ion-input.input--focus {
  border: 0px solid var(--ion-color-light);
}

ion-input {
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: var(--border-radius--small);
  transition: border-color 0.2s;
}

ion-list {
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: var(--border-radius--small);
}

.border-radius {
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: var(--border-radius--small);
}

.border-radius-top {
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: var(--border-radius--small) var(--border-radius--small) 0 0;
}

.border-radius-bottom {
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 0 0 var(--border-radius--small) var(--border-radius--small) !important;
}

.card-modifier {
  width: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 15px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;

  background-color: rgba(0, 0, 0, 0);
  transition: all var(--transition-speed);
}

.card-modifier:hover {
  border-color: var(--ion-color-step-100);
  background-color: var(--ion-color-step-100);
  filter: brightness(var(--hover-brightness));
}

.card-modifier:active {
  filter: brightness(var(--active-brightness));
}

.card-modifier--selected {
  border-color: var(--ion-color-success);
  background-color: rgba(var(--ion-color-success-rgb), 0.05);
}

.card-modifier--selected:hover {
  border-color: var(--ion-color-success-shade);
}

.accordian-content-card-bg-color {
  background-color: var(--ion-card-background);
}

.no-box-shadow {
  box-shadow: unset;
}

.ion-item--game-player-row {
  /* --padding-start: 0; */
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
}

.game-group-card-winner-tile {
  background-color: var(--ion-card-background);
}

.no-margin-y {
  margin-top: 0;
  margin-bottom: 0;
}

.no-margin-x {
  margin-left: 0;
  margin-right: 0;
}

.margin-15 {
  margin: 15px;
}

.margin-15-top {
  margin-top: 15px;
}

.margin-15-bottom {
  margin-top: 15px;
}

.margin-5-start {
  margin-left: 5px;
}

.margin-5-end {
  margin-right: 5px;
}

.margin-10-start {
  margin-left: 10px;
}

.margin-10-end {
  margin-right: 10px;
}

.margin-10-bottom {
  margin-bottom: 10px;
}

.padding-10-start {
  padding-left: 10px;
}

.padding-10-end {
  padding-right: 10px;
}

.padding-10-top {
  padding-top: 10px;
}

.padding-10-bottom {
  padding-bottom: 10px;
}

.scrollable-accordian-content {
  overflow: scroll;
}

.scrollable-accordian-content--small {
  max-height: 300px;
}

.short-max-height {
  max-height: 450px;
  overflow: scroll;
}

.card-margin {
  /* margin: 16px 16px; */
  border-radius: 8px;
}

.applied-modifier-divider {
  width: 1px;
  height: 17px;
  background-color: var(--ion-color-step-200);
  margin-left: 5px;
}

.custom-botton {
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: var(--border-radius--small);
  padding: 5px 10px;

  transition: all var(--transition-speed);
}

.custom-botton:hover {
  filter: brightness(var(--hover-brightness));
  cursor: pointer;
}

.custom-botton:active {
  filter: brightness(var(--active-brightness));
  cursor: pointer;
}

.catchphrase {
  color: var(--ion-color-step-800);
  margin: 10px;
  padding: 10px;
  border-left: 2px solid var(--ion-color-step-200);
}

ion-segment-button {
  --color: var(
    --ion-card-color,
    var(--ion-item-color, var(--ion-color-step-600, #666666))
  );
}

ion-checkbox::part(container) {
  border-radius: 6px;
}
